import React, { useState, useEffect, HTMLAttributes } from "react";
import { Button } from "../Button";
import Icon from "../Icon";
import { twMerge } from "tailwind-merge";

type IProps = {
  className?: HTMLAttributes<HTMLDivElement>["className"];
};

const ScrollToTop = ({ className }: IProps) => {
  const [visible, setVisible] = useState(false);
  const [isScrollable, setIsScrollable] = useState(false);
  const [atBottom, setAtBottom] = useState(false);

  useEffect(() => {
    const checkScrollable = () => {
      setIsScrollable(
        document.documentElement.scrollHeight > window.innerHeight
      );
    };

    const handleScroll = () => {
      if (isScrollable) {
        setVisible(window.scrollY > 300);
      }

      const scrollableHeight =
        document.documentElement.scrollHeight - window.innerHeight;
      setAtBottom(window.scrollY >= scrollableHeight - 10); // Near bottom
    };

    checkScrollable(); // Initial check
    window.addEventListener("resize", checkScrollable);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", checkScrollable);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isScrollable]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  if (!isScrollable) return null; // Hide if the page is not scrollable

  return (
    <Button
      size="sm"
      onClick={scrollToTop}
      className={twMerge(
        `fixed right-5 flex items-center justify-center gap-1 w-[140px] h-[40px] pr-3 border border-black bg-white hover:bg-white text-black rounded-3xl shadow-md transition-all duration-300 ${
          visible ? "opacity-100" : "opacity-0 pointer-events-none"
        } ${atBottom ? "bottom-36 max-md:bottom-52" : "bottom-5"}`,
        className
      )}
    >
      <Icon icon="arrowTop" className="w-5 h-5" />
      <span>Back to Top</span>
    </Button>
  );
};

export default ScrollToTop;
